import React from "react";
import SEO from "../components/seo";
import Layout from "../components/layout";

const IndexPage = ({ handleClick }) => {
  setTimeout(function() {
    window.location.href = "/"; //will redirect to your blog page (an ex: blog.html)
  }, 2000); //will call the function after 2 secs.
  return (
    <Layout>
      <SEO title="Success" />
      <h1 className="center success__message">Thank you for contacting us!</h1>
    </Layout>
  );
};

export default IndexPage;
